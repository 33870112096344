<template>
  <div>
    <div
      class="modal fade p-0"
      id="wluModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-slideout modal-md" role="document">
        <div class="modal-content">
          <div class="b-modal-header">
            <nav class="navbar navbar-light modal-nav fixed-top">
              <div class="d-flex justify-content-between w-100">
                <div data-dismiss="modal" @click="back()">
                  <img
                    class="back-button"
                    height="32"
                    :src="getIcon('chevron-left-solid.svg')"
                    alt="back-button"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <p
                    style="
                      background: #ffffff20;
                      padding: 4px 10px;
                      border-radius: 20px;
                      line-height: 1;
                    "
                    class="mb-0 d-flex align-items-center"
                  >
                    <span
                      v-if="userInfo.account_created"
                      class="font-md-14 font-12"
                      style="font-weight: 500"
                    >
                      <img
                        class="mr-2"
                        width="10"
                        height="10"
                        :src="getIcon('large-yellow-dot.svg')"
                        alt="icon-status"
                      />
                      Registered
                    </span>
                    <span
                      v-else
                      class="font-md-14 font-12"
                      style="font-weight: 500"
                    >
                      <img
                        class="mr-2"
                        width="10"
                        height="10"
                        :src="getIcon('not-enrolled-bullet.svg')"
                        alt="icon-status"
                      />
                      Not enrolled
                    </span>
                  </p>
                </div>
              </div>
            </nav>
          </div>
          <div class="modal-body text-left p-0">
            <div class="top-image-bg d-flex justify-content-center"></div>
            <div class="mod-btn">
              <div @click="toggleDropdown">
                <select
                  name="Type"
                  @change="onChangeRole($event)"
                  @mousedown="handleOpen"
                  @focus="handleOpen"
                  @blur="handleClose"
                  v-model="userInfo.role"
                  class="moduleAct font-weight-bold text-black text-capitalize mb-0 font-12 font-md-16"
                >
                  <option class="text-black" disabled selected value>
                    -- select an option --
                  </option>
                  <option class="text-black bg-" value="student">
                    Student
                  </option>
                  <option
                    class="text-black"
                    :disabled="$store.state.role !== 'admin'"
                    value="instructor"
                  >
                    Instructor
                  </option>
                  <option
                    class="text-black"
                    :disabled="$store.state.role !== 'admin'"
                    value="admin"
                  >
                    Admin
                  </option>
                  <option
                    class="text-black"
                    :disabled="$store.state.role !== 'admin'"
                    value="pod operator"
                  >
                    Pod Operator
                  </option>
                </select>
                <span
                  :class="{
                    'fa-chevron-down': !isOpen,
                    'fa-chevron-up': isOpen,
                  }"
                  class="fas arrow"
                ></span>
              </div>
            </div>
            <div class="py-4">
              <div class="px-4">
                <h3
                  class="modal-heading text-light font-18 font-md-32 p-0 mb-0"
                >
                  {{ userInfo.first_name }} {{ userInfo.last_name }}
                </h3>
                <p class="font-md-16 font-14">{{ userInfo.email }}</p>
                <a
                  class="remove-user font-14 pointer"
                  @click="removeUserModal()"
                  >Remove user</a
                >
              </div>

              <div
                style="background: #111111; margin-top: 24px"
                v-if="$store.state.whiteListUsers.length > 0"
              >
                <p
                  class="font-md-18 font-16 font-weight-bold mb-0"
                  style="
                    background: rgb(36, 36, 36);
                    padding: 10px 20px;
                    border-radius: 8px 8px 0px 0px;
                  "
                >
                  History
                </p>
                <div>
                  <div
                    v-for="(user, u) in paginatedUsers"
                    :key="u"
                    class="d-flex justify-content-between px-4 py-3"
                    style="border-bottom: 0.5px solid #2c2c2c"
                  >
                    <div class="d-flex flex-column">
                      <p class="font-weight-bold font-md-16 font-14 mb-0">
                        {{ user.shortname }}
                      </p>
                      <p class="font-md-16 font-12 text-muted mb-1">
                        {{ getTrimTime(user.start_timestamp) }} |
                        {{ getTrimDate(user.start_timestamp) }}
                      </p>
                      <p class="mb-0 font-md-14 font-12 font-weight-bold py-1">
                        <span
                          class="h-2d py-1 px-2"
                          v-if="user.show_type == '1650'"
                          >2D</span
                        >
                        <span class="h-pod py-1 px-2" v-else>Pod</span>
                      </p>
                    </div>
                    <div style="align-self: center" class="text-center">
                      <a
                        class="font-md-14 font-12 text-capitalize font-weight-bold"
                        style="
                          background: #242424;
                          display: flex;
                          width: 85px;
                          border-radius: 4px;
                          justify-content: center;
                          padding: 8px;
                          line-height: 1;
                        "
                        :class="[
                          `${getUserStatus(user)}` === 'complete'
                            ? 'text-success'
                            : '',
                          `${getUserStatus(user)}` === 'walkaway'
                            ? 'text-danger'
                            : '',
                          `${getUserStatus(user)}` === 'cancelled' ||
                          `${getUserStatus(user)}` === 'no show'
                            ? 'text-danger'
                            : '',
                        ]"
                        >{{ getUserStatus(user) }}</a
                      >
                    </div>
                  </div>
                  <v-pagination
                    class="pt-55 pb-55 table-pagination"
                    v-model="page"
                    :length="Math.round(pagecount)"
                    circle
                    dark
                    :total-visible="10"
                    @input="updatePagination"
                  ></v-pagination>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="removeUserModal"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 514px; max-height: 282px"
      >
        <div
          class="modal-content animate-bottom"
          style="border-radius: 18px !important"
        >
          <div
            class="p-0 modal-body b-gold"
            style="border-radius: 18px !important"
          >
            <div
              class="modal-header mb-0 d-flex p-0 align-items-center import-modaal-header"
              style="background: transparent"
            >
              <h5
                class="modal-title font-md-18 font-16 pl-20 pb-12p pt-22 text-gold text-left kallisto-bold"
              >
                Remove user
              </h5>
            </div>
            <div class="d-flex flex-column text-center">
              <p class="font-16 font-md-18 font-weight-bold mt-38 mb-38">
                Are you sure you want to remove <br />
                {{ userInfo.first_name }} {{ userInfo.last_name }}?
              </p>
              <div
                class="d-flex justify-content-center font-weight-bold mt-1 pt-2 pb-32"
              >
                <button
                  class="mx-2 font-14 cancel os-semibold"
                  data-dismiss="modal"
                  style="border: 1px solid"
                >
                  No
                </button>
                <button
                  @click="removeUserSuccess()"
                  class="mx-2 continue-btn font-14 os-bold"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="changeUserRole"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 514px; max-height: 282px"
      >
        <div
          class="modal-content animate-bottom"
          style="border-radius: 18px !important"
        >
          <div
            class="p-0 modal-body b-gold"
            style="border-radius: 18px !important"
          >
            <div
              class="modal-header mb-0 d-flex p-0 align-items-center import-modaal-header"
              style="background: transparent"
            >
              <h5
                class="modal-title font-md-18 font-16 pl-20 pb-12p pt-22 text-gold text-left kallisto-bold"
              >
                Update User role
              </h5>
            </div>
            <div class="d-flex flex-column text-center">
              <p class="font-16 font-md-18 font-weight-bold mt-38 mb-38">
                Update {{ userInfo.first_name }} {{ userInfo.last_name }} role
                to {{ userInfo.role }}?
                <br />
              </p>
              <div
                class="d-flex justify-content-center font-weight-bold mt-1 pt-2 pb-32"
              >
                <button
                  class="mx-2 font-14 cancel os-semibold"
                  data-dismiss="modal"
                  style="border: 1px solid"
                >
                  No
                </button>
                <button
                  @click="changeUserRole(userInfo.user_id, newRole)"
                  class="mx-2 continue-btn font-14 os-bold"
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="changeUserRoleSuccess"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 514px; max-height: 282px"
      >
        <div
          class="modal-content animate-bottom"
          style="border-radius: 18px !important"
        >
          <div
            class="p-0 modal-body b-gold"
            style="border-radius: 18px !important"
          >
            <div
              class="modal-header mb-0 d-flex p-0 align-items-center import-modaal-header"
            ></div>
            <div class="d-flex flex-column text-center">
              <img
                :src="getIcon('check-solid.svg')"
                alt="checked"
                class="checkin-complete mt-5"
              />
              <p class="font-16 font-md-18 font-weight-bold mt-38 mb-38">
                Role successfully updated.
              </p>
              <div
                class="d-flex justify-content-center font-weight-bold mt-1 pt-2 pb-32"
              >
                <button
                  @click="logout()"
                  class="mx-2 continue-btn font-14 os-bold"
                  data-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade px-0"
      id="removeUserSuccess"
      data-backdrop="exampleModalLabel"
      data-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered w-custom"
        style="max-width: 514px; max-height: 282px"
      >
        <div
          class="modal-content animate-bottom"
          style="border-radius: 18px !important"
        >
          <div
            class="p-0 modal-body b-gold"
            style="border-radius: 18px !important"
          >
            <div
              class="modal-header mb-0 d-flex p-0 align-items-center import-modaal-header"
            ></div>
            <div class="d-flex flex-column text-center">
              <img
                :src="getIcon('check-solid.svg')"
                alt="checked"
                class="checkin-complete mt-5"
              />
              <p class="font-16 font-md-18 font-weight-bold mt-38 mb-38">
                User successfully removed
              </p>
              <div
                class="d-flex justify-content-center font-weight-bold mt-1 pt-2 pb-32"
              >
                <button
                  @click="logout()"
                  class="mx-2 continue-btn font-14 os-bold"
                  data-dismiss="modal"
                >
                  ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import $ from "jquery";
import { API } from "aws-amplify";
import "@fortawesome/fontawesome-free/css/all.css";
export default {
  props: ["userInfo"],
  data() {
    return {
      page: 1,
      pagecount: 0,
      isOpen: false,
      usersPerPage: 10,
      paginatedUsers: [],
      arrowUp: false,
      role: "",
      newRole: "desired-role",
      moduleAct: " Module Act",
      img: "box.png",
      title: "Food Web: Act 2",
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
    };
  },
  computed: {
    whitelistUsers() {
      return this.$store.state.whiteListUsers;
    },
  },

  methods: {
    updatePagination() {
      const start = (this.page - 1) * this.usersPerPage;
      const end = start + this.usersPerPage;
      this.paginatedUsers = this.whitelistUsers.slice(start, end);
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    handleOpen() {
      this.isOpen = true;
      console.log("inhandleopen");
    },
    handleClose() {
      this.isOpen = false;
      console.log("handleColse");
    },
    toggleArrow() {
      this.arrowUp = !this.arrowUp;
    },
    back() {
      this.$emit("backButtonClicked");
    },
    changeUserRole(userId, newRole) {
      $("#changeUserRole").modal("hide");
      $("#changeUserRoleSuccess").modal();
      console.log(userId, newRole);
      const payload = {
        body: {
          role: this.newRole,
          experience_data: {
            mean_value: 0,
          },
        },
      };

      //       {
      //   "role": "string",
      //   "experience_data": {
      //     "customization": {
      //       "mean_value": 0
      //     }
      //   }
      // }

      API.patch("asulink", `/access/user/${userId}`, payload)
        .then(() => {
          this.$toasted.show("User role updated successfully", {
            position: "top-right",
            duration: 5000,
          });
          // Update the UI or perform any other necessary actions
        })
        .catch((err) => {
          this.$toasted.show(`Error: ${err.response.data.message}`, {
            position: "top-right",
            duration: 5000,
          });
          console.error("Error updating user role:", err);
        });
    },

    onChangeRole(event) {
      this.newRole = event.target.value;
      $("#changeUserRole").modal();
    },

    removeUserModal() {
      $("#removeUserModal").modal();
    },
    removeUserSuccess() {
      $("#removeUserModal").modal("hide");
      API.del("asulink", `/access/user/${this.userInfo.user_id}`)
        .then(() => {
          $("#removeUserSuccess").modal();
          $("#wluModal").modal("hide");
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
    },
    getTrimTime(timestamp) {
      return moment(timestamp).format("hh:mm A");
    },
    getTrimDate(timestamp) {
      return moment(timestamp).format("ddd, MMM D, YYYY");
    },
    getImage(img) {
      return require("@/assets/images/" + img);
    },
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    getUserStatus(user) {
      if (user.status_description == "complete") {
        let userData = this.$store.state.whiteListUserData.find(
          (tempUser) => tempUser.session_id === user.session_id
        );
        if (userData != undefined) {
          try {
            let configs =
              userData.data._initial_conditions.event_configurations;
            let eventConfi = configs.find(
              (element) => element.event_name == "MISSION SUCCESS"
            );
            if (eventConfi != undefined) {
              let event_id = eventConfi.event_id;
              if (userData.data[event_id]) {
                return "complete";
              } else return "walkaway";
            } else return "walkaway";
          } catch (err) {
            return "walkaway";
          }
        } else {
          return "walkaway";
        }
      }
      return user.status_description;
    },
  },
  mounted() {
    setTimeout(() => {
      this.pagecount = Math.ceil(
        this.whitelistUsers.length / this.usersPerPage
      );
      this.updatePagination();
    }, 5000);
  },
};
</script>
<style lang="scss" scoped>
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  margin-top: 0px !important;
}
.fa-chevron-down {
  transform: rotate(0deg);
}
.fa-chevron-up {
  transform: rotate(180deg);
}
select {
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px; /* Adjust as needed to make space for the custom arrow */
}

.arrow {
  position: absolute;
  right: 10px; /* Adjust based on your layout */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #000; /* Dark color for the arrow */
}

select:focus + .arrow {
  color: #000; /* Ensure the arrow remains dark when the select is focused */
}
.checkin-complete {
  background: #ffc627;
  border-radius: 60px;
  opacity: 0.7;
  padding: 12px;
  height: 48px;
  width: 48px;
}
.remove-user {
  background: #2e2e2e;
  border-radius: 4px;
  padding: 8px 15px;
  color: #fff !important;
  :hover {
    background: #5d5d5d !important;
  }
}
.cancel {
  border-radius: 8px !important;
  height: 42px !important;
  width: 136px;
  :hover {
    color: black !important;
    background: white;
  }
}
.back-button {
  background: #141d2282;
  padding: 7px 11px;
  border-radius: 50%;
}
.h-pod {
  background: #ffc627;
  color: #151515 !important;
  border-radius: 4px;
}
.h-2d {
  background: #0e4f74;
  border-radius: 4px;
}
.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 0 0 auto;
  background: #fff;
}
.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(100%, 0) scale(1);
  transform: translate(100%, 0) scale(1);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  display: flex;
  align-items: stretch;
  -webkit-box-align: stretch;
  height: 100%;
}
.modal-dialog-slideout .modal-content {
  border: 0;
}
.modal-dialog-slideout .modal-header,
.modal-dialog-slideout .modal-footer {
  height: 69px;
  display: block;
}
.modal-dialog-slideout .modal-header h5 {
  float: left;
}

.modal {
  width: 101% !important;
}
.modal-md {
  width: 50%;
}
@media only screen and (max-width: 991px) {
  .top-image-bg {
    height: 150px !important;
  }
  .modal-md {
    width: 100%;
  }
  .showinDesktop {
    display: none;
  }
}
@media only screen and (min-width: 991px) {
  .showinMobile {
    display: none;
  }
}

.modal-header {
  background: rgb(0, 0, 0.2);
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-body {
  color: white;
  background: #151515;
}
.modal-heading {
  padding-top: 6px;
  padding-bottom: 20px;
  font-weight: 700;
  color: #000011;
}

.mdal-list {
  font-size: 14px;
}
.modal-p {
  font-size: 14px;
}

.close,
.close:hover {
  font-size: 21px;
  line-height: 1;
  color: #ffffff;
  font-weight: 400;
  opacity: 1 !important;
  cursor: pointer;
}
.modal-nav {
  padding-left: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.modal-dialog {
  max-width: 600px;
}
.mod-btn {
  position: absolute;
  margin-top: -24px;
  left: 24px;
}
.moduleAct {
  background: #fafafa !important;
  color: #151515 !important;
  padding: 8px 12px;
  border-radius: 2px;
}
.top-image-bg {
  max-width: 100% !important;
  height: 283px;
  background-image: linear-gradient(to bottom, #fff0, #191919),
    url("../../assets/images/alien_1.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

#wluModal {
  .student-info {
    border-top: 0.5px solid #ffffff;
    border-bottom: 0.5px solid #ffffff;
  }
  hr {
    border: 0.5px solid #ffffff;
  }
}
</style>
